import React, { useState, useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Container, Typography, Button, Grid, InputBase } from '@material-ui/core'

import { ReactComponent as LogoTEA } from 'assets/images/main-logo-tea.svg'
import { ReactComponent as LogoGE } from 'assets/images/main-logo-ge.svg'
import { StringUtils } from 'utils'
import { STRINGS } from 'consts'
import { AdminContext } from 'contexts'
import { useApiCall, useCheckFormErrors, useLogIn } from 'hooks'
import { snackbar } from 'components'
import { withSnackbar } from 'hooks/useCheckFormErrors'

import { useStyles } from './styles'
import { LoginPayload, LoginResponse } from './types'
import { login } from './api'
import { useStyleContext } from 'contexts/StyleContext/useStyleContext'
import { ConfigUtils } from 'utils/config'

const rules = {
  email: [
    {
      validate: (email: string) => StringUtils.isEmail(email),
      message: 'Debe ser un mail válido.',
    },
  ],
  password: [
    {
      validate: (password: string) => password.length >= STRINGS.PASSWORD_MIN_LENGTH,
      message: `La contra debe tener al menos ${STRINGS.PASSWORD_MIN_LENGTH} caracteres.`,
    },
  ],
}

const Login: React.FC = () => {
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { admin } = useContext(AdminContext)
  const [logIn] = useLogIn()

  const [logoUrl, setLogoUrl] = useState('')

  const { style, getFromStorage, isLoadingStyle } = useStyleContext()

  useEffect(() => {
    const applyLogo = async () => {
      if (style) {
        setLogoUrl(style.storeLogoUrl)
      } else {
        const styleUpdate = await getFromStorage()
        if (styleUpdate) setLogoUrl(styleUpdate.storeLogoUrl)
      }
    }
    applyLogo()
  }, [isLoadingStyle])

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = e => {
    setEmail(e.target.value)
  }
  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = e => {
    setPassword(e.target.value)
  }

  const [loginApi] = useApiCall<LoginPayload, LoginResponse>(login)

  const data = { email, password }
  const checkErrors = withSnackbar(useCheckFormErrors(data, rules))

  const handleKeyDown: React.KeyboardEventHandler = e => {
    const enterKeyCode = 13
    if (e.keyCode === enterKeyCode) {
      onSubmit()
    }
  }

  const onSubmit = async () => {
    if (!checkErrors()) return
    try {
      const response = await loginApi({
        email,
        password,
      })

      const { token, user } = response
      logIn(token, user)
    } catch (err) {
      snackbar.show('Usuario o contraseña incorrectos.')
    }
  }

  const defaultLogo = () => {
    return ConfigUtils.isTeaConnection() ? (
      <LogoTEA height={200} width={400} />
    ) : (
      <LogoGE height={200} width={400} />
    )
  }

  if (admin) return <Redirect to="/home" />

  return (
    <Container className={classes.container} maxWidth={false}>
      {logoUrl ? (
        <div className={classes.logoContainer}>
          <img src={logoUrl} alt="Logo" className={classes.logo} />
        </div>
      ) : (
        defaultLogo()
      )}
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item>
          <Typography variant="h5" component="h2" className={classes.label}>
            Ingresá
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center">
          <InputBase
            className={classes.input}
            inputProps={{
              className: classes.inputInner,
            }}
            placeholder="Usuario / Mail"
            fullWidth
            required
            value={email}
            onChange={handleChangeEmail}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item container xs={12} justify="center">
          <InputBase
            className={classes.input}
            inputProps={{
              className: classes.inputInner,
            }}
            placeholder="Contraseña"
            type="password"
            fullWidth
            required
            value={password}
            onChange={handleChangePassword}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" className={classes.link}>
            Olvidé mi contraseña
          </Typography>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={onSubmit}
          >
            Listo!
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export { Login }
